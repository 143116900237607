//Sectie aanmelden voor de nieuwsbrief

@import "newsLetter/newsLetter";

footer {
  section {
    overflow: hidden;
  }
}

.contact-us {
  background-color: #f6e3e790;
  padding-top: 30px;
  padding-bottom: 40px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      picture {
        width: 56px;
        height: 56px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .title {
    font-size: 2.1875rem;
    font-family: "Satisfy Regular", serif;
  }

  .content {
    font-size: $font-size-base;
    font-family: $font-family-body;
  }

  .contact {
    font-size: 1rem;
    color: #D59F61;
    font-weight: 600;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
  }

  .chat-button {
    background-color: #000000;
    font-size: $font-size-base;
    font-family: $font-family-button;
    font-weight: 600;
    color: white;
    min-width: 172px;
    border-radius: 0.938rem;
    text-transform: capitalize;

    &:focus, &:active, &:focus-visible, &:hover {
      background-color: #000000 !important;
      color: white !important;
      border: none !important;
    }

    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
}

.newsletter-store-outlet-section {
  background-color: $color-cotton-candy;
    @media screen and (min-width: 992px) {
      padding-bottom: 3.125rem;
      padding-top: 3.125rem;
    }

  .newsletter {
    text-align: left;

    h2 {
      color: black;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.375rem;
      font-family: $font-family-header;
      padding-bottom: 1.5rem;
      padding-top: 0;
      margin-bottom: 0;

      @media screen and (max-width: 992px) {
        font-size: 1.25rem;
        padding-top: 2.813rem;
      }
    }

    .newsletter-text {
      max-width: 630px;

      @media only screen and (max-width: 992px) {
        max-width: 100%;
      }

      p {
        color: black;
        font-family: $font-family-body;
        font-size: $font-size-base;
        padding-bottom: 1.188rem;
        margin-bottom: 0;

        b, strong {
          font-size: $font-size-base;
          font-family: $font-family-body;
          font-weight: 600;
        }
      }
    }
    .newsletter-widget {
      .newsletter-subscribe-widget {
        .input-email {
          padding: 0.75rem 1.25rem 0.75rem 0.688rem;
          border-right: none;
        }
      }
    }
  }

  .store-outlet {
    text-align: left;

    @media screen and (max-width: 992px) {
      padding-bottom: 3.125rem;
    }

    .title-store-outlet {
      color: black;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.375rem;
      font-family: $font-family-header;
      padding-bottom: 1.5rem;
      padding-top: 0;
      margin-bottom: 0;

      @media screen and (max-width: 992px) {
        font-size: 1.25rem;
        padding-top: 2.813rem;
      }
    }

    .store-outlet-text {
      max-width: 630px;

      @media only screen and (max-width: 992px) {
        max-width: 100%;
      }

      p {
        color: black;
        font-family: $font-family-body;
        font-size: 1rem;
        padding-bottom: 1.188rem;
        margin-bottom: 0;

        b, strong {
          font-size: $font-size-base;
          font-family: $font-family-body;
          font-weight: 600;
        }
      }
    }

    a {
      &.btn-store-outlet {
        background-color: white;
        color: $color-pitch-black;
        font-family: $font-family-body;
        font-size: 1rem;
        width: 100%;

        @media only screen and (min-width: 992px) {
          width: unset;
          min-width: 172px;
        }
      }
    }
  }
}


//Sectie accordion met links naar andere gedeeltes website

.footer-list{
  background-color: $color-cotton-candy;
  h2{
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    margin-bottom: 1rem;
  }

  ul {
    margin: 0 !important;
    padding: 0 !important;

    li {
      list-style: none;

      a {
        font-family: $font-family-body;
        font-weight: 400;
        font-size: $font-size-base;
        list-style: none;
        text-decoration: none;
        color: black;
        line-height: 1.875rem;
      }
    }
  }
}

.unfold {
  background-color: $color-cotton-candy;

  .accordion-item {
    --bs-accordion-border-width: 0;
    background-color: $color-cotton-candy;
    margin-bottom: 6px;
  }

  .accordion-button {
    background-color: $color-cotton-candy;
    padding:0;
    --bs-accordion-btn-icon-transition: 0;

    h2 {
      font-size: 1rem;
      text-transform: uppercase;
      color: black;
      font-weight: 600;
      line-height: 1.313rem;
    }

    &:not(.collapsed) {
      color: black;
      background-color: $color-cotton-candy;
      box-shadow: none;
    }

    &:focus {
      border-color: transparent;
      outline: 0;
      box-shadow: none;
    }
  }

  .accordion-button::after {
    width: 33px;
    height: 33px;
    background-image: url("../images/icons/arrow.svg") !important;
    background-size: cover;
  }

  .accordion-button:not(.collapsed)::after {
      transform: rotate(180deg);
  }

  //.collapsing {
  //  -webkit-transition: none;
  //  transition: none;
  //  display: none;
  //}

  .accordion-body {
    padding-left: 0;
    padding-top: 10px;

    ul  {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        text-decoration: none;
        text-align: left;
        list-style-type: none;

        a {
          font-weight: 400;
          font-size: $font-size-base;
          text-decoration: none;
          color: black;
          font-family: $font-family-body;
          line-height: 1.875rem;
        }
      }
    }
  }
}

.customer-satisfy {
  background-color: $color-cotton-candy;
  iframe{
    //@media screen and (max-width: 992px){
    //  width: 239px;
    //  height: 116px;
    //}
  }

  .review-platform {
    padding: 0 100px;
  }

}

//Sectie met alle links naar social media en betalingsmogelijkheden mobiel
//wordt dit eerste stukje nog gebruikt?
.mobile-footer-bottom {
  .payment-methods-mobile {
    ul {
      li {
        display: inline-block;
        list-style: none;
        text-decoration: none;
      }
    }
  }
}

.social-follow-us {
  text-align: center;
  justify-content: center;
  padding-top: 44px;

  h2 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 8px;
  }

   ul {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 44px;

    li {
      display: inline-block;
      list-style: none;
      text-decoration: none;
      picture{
        display: inline;
        a {
          color: black;
        }
      }

    }
  }
}


//Sectie met alle links naar social media
.social-follow-us-desktop {
  ul {
    padding-left: 0;
    li {
      display: inline-block;
      list-style: none;
      text-decoration: none;

      a {
        color: black;

        picture {
          height: 51px;
          width: 51px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.social-media {
  align-items: end;
  //height: 250px;
  background-color: $color-cotton-candy;
  overflow: hidden;
  //background-image: url("https://static-test.topvintage.net/new-design/footer/phone.jpg");
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-position: bottom center;

  h2 {
    color: black;
    font-weight: 600;
    font-size: 1rem;
    font-family: 'Josefin Sans', serif;
    text-transform: uppercase;
  }

  p {
    color: black;
    line-height: 1rem;
    font-size: 0.7rem;
    font-family: 'Josefin Sans', serif;

    a {
      color: black;
      font-weight: bold;
      text-decoration: none;
      img {
        width: 240px;
      }
    }
  }
  ul {
    li {
      display: inline-block;
      padding: 0;
      margin: 0;
      img {
        width: 53px;
      }
    }
  }
  .footer-logo {
    display: inherit;
    text-align: left;
    font-family: $font-family-body;
    font-size: 0.8125rem;
    img{
      width: 249px;
      height: 32px;
    }
    @media only screen and (max-width: 992px){
      text-align: center;
      align-self: center;
      justify-self: center;
      justify-content: center;
    }

    //@media only screen and (max-width: 768px) {
    //  text-align: center;
    //  justify-content: center;
    //}

    //.logo {
    //  text-decoration: none;
    //  color: black;
    //  font-size: 1.375rem;
    //  font-family: 'Bely Display W00 Regular', serif;
    //
    //  @media only screen and (max-width: 768px) {
    //    text-align: center;
    //    font-size: 1.875rem;
    //  }
    //
    //  span {
    //    color: #e57576;
    //    font-weight: bolder;
    //  }
    //}
    p{
      font-size: 0.8rem;

      @media only screen and (max-width: 768px) {
        text-align: center;
        font-size: 0.813rem;
        color: black;
      }
    }
  }
  .kiyoh-col{
    margin-top: -18px;
  }

  .privacy-statement-mobile {
    margin-bottom: 2.25rem;
    text-align: center;
    justify-content: center;
    font-family: $font-family-body;
    font-size: 0.8125rem;

    p {
      font-family: $font-family-body;
      font-weight: 400;
      font-size: 0.813rem;
      text-transform: none;
      line-height: 23px;
      margin: 0;
      padding-bottom: 1.5rem;

      span {
        display: block;
      }
    }

    span {
      font-family: $font-family-body;
      font-weight: 400;
      font-size: 0.813rem;
    }

    :last-child {
      padding-top: 1.5rem;
    }
  }

  .privacy-statement {
    font-family: $font-family-body;
    font-size: 0.8125rem;

    p {
      font-family: $font-family-body;
      font-size: 0.8125rem;
      display: inline-block;
    }

    span {
      font-family: $font-family-body;
      font-weight: 400;
      font-size: 0.813rem;
    }
  }
  .payment-icons {
    @media screen and (max-width: 992px) {
      justify-content: center;
      max-width: 366px;
      margin: 0 auto;
    }

    col {
      @media screen and (max-width: 992px) {
        flex: unset;
        width: 25%;
      }
    }
    .icon-wrapper {
      background-color: white;
      border-radius: 0.313rem;
      width: 65px;
      margin: 0 auto;

      .payment-icon-cart {
        height: 28px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }
    }
  }
}
