.widget.store {
  font-size: 1rem;
  line-height: 1.5rem;

  .store-small {
    max-width: 720px;
    margin: 0 auto;
  }

  .store-block {
    margin: 50px 0;
    #video-container{
      position: relative;
      #video-player{
        width: 100%;
        position: relative;
        object-fit: inherit;
        p{
          margin-bottom: 10px;
        }
      }
    }
    .store-image-slider{
      position: relative;

      .swiper-button-prev, .swiper-button-next{
        top: 50% !important;
        color: black;
      }
      .swiper-button-prev {
        left: -35px;
      }
      .swiper-button-next {
        right: -35px;
      }

      @media only screen and (max-width: 992px) {
        .swiper-button-prev {
          left: 5px;
          color: #FFF;
        }
        .swiper-button-next {
          right: 5px;
          color: #FFF;
        }
      }

      .swiper-pagination-bullet{
        display: none;
      }
    }
    .store-instagram{
      .follow-us-btn{
        padding: 10px;
        background-color: $color-cotton-candy;
        width: 30%;
      }
    }
    .faq-list {
      margin-top: 25px;

     .title{
       font-weight: 700;
      }
    }
  }

  .store-title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;

    &.store-primary-title {
      margin-bottom: 40px;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }

  .store-text {
    &.center {
      text-align: center;
    }
  }

  .store-address, .store-times {
    margin-bottom: 20px;
  }

  .store-instagram {
    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    img {
      margin-left: 15px;

      @media (max-width: 992px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .store-instagram-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .store-title {
        margin-bottom: 0;
        margin-top: 0;
      }

      @media (max-width: 992px) {
        flex-wrap: wrap;
      }
    }

    .topvintage-button {
      color: white;
    }
  }

  .store-team-text {
    margin: 20px auto 60px auto;
    text-align: center;
    font-family: $font-family-header;
    line-height: 1.3em;
    font-size: 2.2em;
  }

}
