.product-block{
  //For switching image when hovering
  .switcher {
    position: relative;
    z-index: 2;

    //@media (max-width: 768px) {
    //  min-height: calc( (50vw - 39px) / 284 * 429); // prevents CLS | 50vw = half page width | 39px = border and page-padding | 199*298 = category image dimensions
    //}

    &:not(.disabled):hover picture:first-of-type {
      opacity: 0;
    }

    .images {
      overflow: hidden;
      position: relative;

      a {
        display: block;
      }

      picture {
        display: block;
        min-width: 100%;
        position: relative;
        transition: opacity ease-in-out 200ms;
        z-index: 2;
        aspect-ratio: 2 / 3;

        img{
          min-width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      picture:nth-of-type(2) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  .title{
    flex: 3;
    overflow: hidden;
    .brand{
      display: block;
      font-family: $font-family-header;
      font-size: 0.688rem !important;
      line-height: 0.688rem !important;
      font-weight: 400;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 1px;
    }

    .name-price-container {
      font-family: $font-family-body;
      line-height: $font-size-base;
      font-size: 0.813rem;

      .name {
        display: block;
        padding-bottom: 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .price {
        font-size: 0.813rem !important;
        line-height: 1rem !important;
      }
    }
  }

  .wishlist {
    display: inline-block;
    color: black;
    font-size: 0.8rem;

    span.wishlist-icon {
      display: inline-block;
      position: relative;
      cursor: pointer;
      width: 33px;
      height: 29px;
      object-fit: cover;
      background-size: cover;

      &.wishlist-heart {
        background-image: url("../images/icons/heart-logo.svg");
        @media screen and (max-width: 992px){
          background-image: url("../images/icons/heart-mini-logo.svg");
          width: 21px;
          height: 19px;
        }
      }

      &.in-wishlist {
        background-image: url("../images/icons/heart-logo-active.svg");
        @media screen and (max-width: 992px){
          background-image: url("../images/icons/heart-mini-logo-active.svg");
          width: 21px;
          height: 19px;
        }
      }

      &.wishlist-trashcan {
        background-image: url("../images/icons/trashcan.svg");
        width: 19px;
        height: 19px;
      }
    }

    .itemsWishlist {
      display: block;
      color: black;
      font-size: 0.81rem;
      text-align: center;
      font-family: $font-family-body;
      font-weight: 400;
      margin-top: -0.25rem;
    }
  }
}

.productslider-offcanvas {
  .product-block {
    .wishlist {
      margin-right: 1px;

      span.wishlist-icon {
        width: 22px;
        height: 19px;
      }

      .itemsWishlist {
        font-size: 0.688rem;
        margin-top: -0.5rem;
      }
    }
  }
}
