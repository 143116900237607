// ==== Trengo Chat

.trengo-chat-button {
    position: fixed;
    bottom: 25px;
    right: 20px;
    z-index: 9999;
    width: 60px;
    height: 60px;
    background: #FFFFFF url('../images/icons/chatWidget/chatwidget-icon.svg?vs1') no-repeat center;
    background-size: 60%;
    border-radius: 22px;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    color: #000000;
    font-size: 30px;
    line-height: 60px;
    overflow: hidden;
    //box-shadow: 0 0 8px rgba(0,0,0,.3)!important;
    border: $input-border;
}

#trengo-web-widget > div > div.TrengoWidgetPanel__iframe{
    bottom: 90px !important;
    @media only screen and (min-width: 450px){
        max-height: 600px !important};
    width: 350px;
}



// BACK TO TOP BUTTON
// Mobile and desktop
.back-to-top {
    width: 60px;
    height: 60px;
    background: white;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999; // same as Zendesk button
    border-radius: 22px;
    border: $input-border;
    opacity: 0;
    transition: opacity 300ms;
    cursor: pointer;

    &.bottom-margin {
        bottom: 95px;
    }

    &.show {
        opacity: 1;
    }

    .arrow-line {
        display: block;
        width: 25px;
        height: 2px;
        transform: rotate(45deg);
        background-color: black;

        &:last-child{
            transform: rotate(-45deg);
            margin-left: 16px;
            margin-top: -2px;
        }
    }

    .arrow-up {
        transform: rotate(180deg);
        height: 28px;
        position: absolute;
        margin-left: 9px;
    }
}
