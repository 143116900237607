@import "bootstrap/scss/functions";
$enable-negative-margins: true;
$container-max-widths: ( lg: 1200px );

// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";

// AND NOW .... LET's OVERRULE !

// Basics
@import "base/fonts";
@import "base/colors";
@import "base/general";
@import "base/icons";
@import "base/forms";
@import "base/links";
@import "base/prices";

// Components
@import "components/breadcrumbs";
@import "components/pagination";
@import "components/chatAndBackToTop";
