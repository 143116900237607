input[type=text]:not(.header-search),
input[type=password],
input[type=number],
input[type=email],
input[type=date],
input[type=datetime-local],
input[type=search],
input[type=tel],
select {
  height: 50px;
  border: $input-border;
  font-family: $font-family-body;
  padding-left: 0.625rem;
  transition: border 200ms ease-in-out;
}

input:not([type=range]):focus, textarea:focus{
  border: 0.063rem solid $color-pitch-black !important;
}

input.error,
input:user-invalid,
select.error,
select:user-invalid,
textarea.error,
textarea:user-invalid {
  border: 0.063rem solid $color-super-rose !important;
}
