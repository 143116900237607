$font-family-header:      'Josefin Sans', sans-serif !default;
$font-family-body:        'Quicksand', sans-serif !default;

$font-family-button:      'Quicksand', sans-serif !default;

$font-size-base:          1rem !default; // = 16px
$font-size-large:         ceil(($font-size-base * 1.25)) !default;
$font-size-small:         ceil(($font-size-base * 0.813)) !default; // 13px

$font-size-h1:            floor(($font-size-base * 2.5)) !default;
$font-size-h2:            floor(($font-size-base * 2.15)) !default;
$font-size-h3:            ceil(($font-size-base * 1.7)) !default;
$font-size-h4:            ceil(($font-size-base * 1.25)) !default;
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.813)) !default;

@font-face {
  font-family: "Josefin Sans";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Josefin_Sans/JosefinSans-Regular-400.ttf") format("truetype");
}

@font-face {
  font-family: "Josefin Sans";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Josefin_Sans/JosefinSans-SemiBold-600.ttf") format("truetype");
}

@font-face {
  font-family: "Josefin Sans";
  font-weight: bold; // = 700
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Josefin_Sans/JosefinSans-Bold-700.ttf") format("truetype");
}

//@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: "Quicksand";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Quicksand/Quicksand-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Quicksand/Quicksand-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Quicksand/Quicksand-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Quicksand/Quicksand-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Quicksand/Quicksand-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Quicksand/Quicksand-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Quicksand/Quicksand-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Quicksand/Quicksand-BoldItalic.ttf") format("truetype");
}




@font-face {
  font-family: "Bely Display W00 Regular";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Bely-Display-W00-Regular.woff2") format("woff2"),
  url("../fonts/Bely-Display-W00-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Satisfy Regular";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Satisfy-Regular.ttf") format("truetype");
}
