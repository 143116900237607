.ico {
  display: inline-block;
  line-height: 1;
  position: relative;
  text-indent: -9999px;
  vertical-align: middle;
  background-repeat: no-repeat;

  // === Language flags
  &.lang-nl        { width: 22px; height: 16px; background-position: -81px 0; }
  &.lang-en        { width: 22px; height: 16px; background-position: -192px 0; }
  &.lang-de        { width: 22px; height: 16px; background-position: -125px 0; }
  &.lang-fr        { width: 22px; height: 16px; background-position: -147px 0; }
  &.lang-es        { width: 22px; height: 16px; background-position: -169px 0; }

  &.payments {
    width: 572px;
    height: 26px;
    background-position: 0 -409px;
  }

  &.ideal {
    width: 36px;
    background-image: url('../images/icons/paymentmethods/ideal.svg?v=2');
  }

  &.paypal {
    width: 67px;
    background-image: url('../images/icons/paymentmethods/paypal.svg');
  }

  &.paypal-msp {
    width: 67px;
    background-image: url('../images/icons/paymentmethods/paypal.svg');
  }

  &.visa {
    width: 58px;
    background-image: url('../images/icons/paymentmethods/visa.svg');
  }

  &.maestro {
    width: 40px;
    background-image: url('../images/icons/paymentmethods/maestro.svg');
  }

  &.mastercard {
    width: 50px;
    background-image: url('../images/icons/paymentmethods/mastercard.svg?v=2');
  }

  &.mistercash {
    width: 50px;
    background-image: url('../images/icons/paymentmethods/bancontact.svg?v=2');
  }

  &.giropay {
    width: 52px;
    background-image: url('../images/icons/paymentmethods/giropay.svg');
  }

  &.sofort {
    width: 59px;
    background-image: url('../images/icons/paymentmethods/sofort.svg');
  }

  &.fashioncheque {
    width: 90px;
    top: 1px;
    background-image: url('../images/icons/paymentmethods/fashioncheque.svg');
  }

  &.vvvgiftcard {
    width: 34px;
    background-image: url('../images/icons/paymentmethods/vvv-giftcard.svg');
  }

  &.banktrans {
    width: 51px;
    background-image: url('../images/icons/paymentmethods/banktransfer-nl.svg');

    &:lang(de) {
      background-image: url('../images/icons/paymentmethods/banktransfer-de.svg');
    }

    &:lang(en) {
      background-image: url('../images/icons/paymentmethods/banktransfer-en.svg');
    }

    &:lang(fr) {
      background-image: url('../images/icons/paymentmethods/banktransfer-fr.svg');
    }
  }

  &.amazonbtn {
    width: 35px;
    background-image: url('../images/icons/paymentmethods/amazonpay.svg');
  }

  &.americanexpress {
    background-image: url('../images/icons/paymentmethods/amex.svg');
  }

  &.cartebancaire {
    width: 36px;
    background-image: url('../images/icons/paymentmethods/cartebancaire.svg');
  }

  &.klarna {
    width: 56px;
    background-image: url('../images/icons/paymentmethods/klarna.svg?v=2');
  }

  &.afterpay {
    width: 50px;
    background-image: url('../images/icons/paymentmethods/riverty.svg');
  }

  &.inghome {
    width: 72px;
    background-image: url('../images/icons/paymentmethods/ing-homepay.svg');
  }

  &.belfius {
    width: 53px;
    background-image: url('../images/icons/paymentmethods/belfius.svg');
  }

  &.kbc {
    width: 34px;
    background-image: url('../images/icons/paymentmethods/kbc.svg');
  }

  &.applepay {
    width: 49px;
    background-image: url('../images/icons/paymentmethods/applepay.svg?v=2');
  }

  &.alipay {
    width: 67px;
    background-image: url('../images/icons/paymentmethods/alipay.svg');
  }
}


// Arrows
.arrow-dropdown {
  margin-top: 1px;

  .arrow-line {
    display: block;
    width: 16px;
    height: 3px;
    transform: rotate(45deg);
    background-color: $topvintage-purple;

    &:last-child{
      transform: rotate(-45deg);
      margin-left: 10px;
      margin-top: -3px;
    }
  }

  &.arrow-up {
    transform: rotate(180deg);
  }
}

.arrow-left {
  .arrow-line {
    display: block;
    width: 16px;
    height: 3px;
    transform: rotate(-45deg);
    background-color: $topvintage-purple;

    &:last-child {
      transform: rotate(45deg);
      margin-left: 0;
      margin-top: 6px;
    }
  }
}
