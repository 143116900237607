a {
  cursor: pointer;
  color: $color-ginger-gold;
  font-family: $font-family-body;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: black;
    text-decoration: none;
  }
}
