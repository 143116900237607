.swiper {
  --swiper-pagination-color: white;
  --swiper-pagination-bullet-horizontal-gap: 0.125rem;

  .swiper-slide.ns-styling {
    max-width: calc(25% - 1.019rem);
    margin-right: 1.375rem;
  }

  .swiper-button-next {
    color: black;
    //background-color: white;
    //padding: 20px;
    //border-radius: 50%;
  }
  .swiper-button-prev {
    color: black;
    //background-color: white;
    //padding: 20px;
    //border-radius: 50%;
  }

  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;
      border: 1px solid black;
      background-color: white;
      opacity: 1 !important;

      &.swiper-pagination-bullet-active {
        background-color: black;
      }
    }
  }

  .swiper-slide-duplicate {
    img.blur-up {
      -webkit-filter: none;
      filter: none;
      //transition: filter 100ms, -webkit-filter 100ms;
    }
  }
}
