.style-advice-form {
  input[type=text], input[type=tel] {
    width: 100%;
  }

  .style-advice-form-agree {
    label {
      input[type=checkbox] {
        vertical-align: middle;
        cursor: pointer;
        height: 25px;
        line-height: normal;
        margin: 0;
        padding: 0;
        width: 25px;
        display: inline-block;
        margin-top: 3px;

        &:checked {
          accent-color: black;
        }
      }

      p {
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
  }
}