// Basics
@import "styles";

//Import van header/footer
@import "components/header";
@import "components/footer";

//Import van components
@import "components/swiper";
@import "components/productBlock";
@import "components/productsSlider";

@import "base/content";
@import "components/subMenu";
@import "components/accountView/passwordNew";
@import "components/newsLetter/newsLetter";
@import "components/newsLetter/emailPreferences";
@import "components/media/storeMedia";

@import "components/store/storeWidget";
@import "components/store/styleAdvice";

//Import van external components
@import "swiper-bundle.min";
@import "fontawesome.scss";

#main_content {
  img {
    @media only screen and (max-width: 1224px) {
      max-width: 100% !important;
      height: auto !important;
    }
  }

  button {
    line-height: 1.875rem;
    padding: 0.625rem;
    font-size: 1rem !important;
    font-family: $font-family-button !important;
    font-weight: 600 !important;
    height: 50px !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    border: none !important;
    border-radius: 10px !important;
    background-color: black;
    color: white;

    &:focus, &:active, &:focus-visible, &:hover {
      border: none !important;
    }

    &.btn-subscribe-newsletter {
      border-radius: 0 !important;
    }
  }

  .section-tp:not(.submenu) {
    font-family: $font-family-body;
        
    a:not(.btn) {
      cursor: pointer;
      color: $color-ginger-gold;
      font-family: $font-family-body;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: black;
        text-decoration: none;
      }

      width: 355px;

      @media only screen and (max-width: 992px) {
        width: 363px;
      }
    }
  }

  iframe {
    width: calc(100vw - 28px);
    aspect-ratio: 1/1.78;
  }
}
