@media only screen and (min-width: 992px)  and (max-width: 1200px) {
  [data-reference="home"] {
    .product-slider {
      margin: 0 14px !important;
    }
  }
}

.product-slider {
  position: relative;
  margin-right: -14px;

  @media only screen and (min-width: 992px)  and (max-width: 1224px) {
    margin-right: 0;
  }

    &.recommendations {
    @media only screen and (max-width: 992px) {
      padding-bottom: 0.7rem;
      padding-top: 1.2rem;
    }
  }

  .title-row {
    margin-bottom: 7px;
    padding-right: 0;
    padding-left: 0;

    .title {
      h2 {
        color: black;
        font-size: 1.75rem;
        font-family: $font-family-header;
        text-align: left;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0;
        @media only screen and (max-width: 992px) {
          font-size: 1.375rem;
          margin-bottom: 12px;
        }
      }
    }

    .button {
      a {
        font-family: $font-family-body;
        font-size: $font-size-base;
        font-weight: 600;
        color: #D59F61;
        text-decoration: none;
        margin-top: 5px;
        display: block;

        @media only screen and (max-width: 992px) {
          margin-top: 0;
          margin-bottom: 5px;
        }
      }
    }
  }

  .swiper {
    @media only screen and (max-width: 992px) {
      margin-left: -14px;
    }

    .swiper-button-next, .swiper-button-prev {
      top: 45%;
      width: 50px;
      height: 50px;
      background-color: white;
      border-radius: 50%;

      &::after {
        color: black;
        --swiper-navigation-size: 2rem;
      }

      &.swiper-button-disabled {
        visibility: hidden;
        opacity: 0;
        transition: 0.5s ease-in-out;
      }

      @media only screen and (max-width: 992px) {
        display: none;
      }
    }

    .swiper-button-next {
      right: 22px;
    }

    .swiper-button-prev {
      left: 22px;
    }

    .swiper-button-next::after {
      margin-left: 4px;
    }

    .swiper-button-prev::after {
      margin-right: 4px;
    }
  }
}

.productslider-offcanvas {
  .product-slider {
    padding-top: 40px !important;
    margin-right: -16px;
    margin-left: -16px;
    overflow: hidden;

    @media only screen and (max-width: 992px) {
      margin-bottom: 35px;
    }

    .ps-container, .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .title-row {
      padding-left: 16px;
      .title {
        h2 {
          font-size: 1.375rem;
        }
      }
    }

    .swiper {
      @media only screen and (max-width: 992px) {
        margin-right: 0;
      }
    }

    &.recommendations {
      @media only screen and (max-width: 992px) {
        padding-bottom: 0.7rem;
        padding-top: 1.2rem;
      }
    }
  }
}
