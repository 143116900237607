.password-forgot-new {
  @media only screen and (max-width: 992px) {
    padding-left: 0;
    padding-right: 0;
  }
  .password-forgot, .password-new {
    h1 {
      font-family: $font-family-header;
      font-size: 2.125rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 2.25rem;

      @media only screen and (max-width: 992px) {
        font-size: 1.625rem;
        margin-bottom: 2rem;
      }
    }

    p {
      font-family: $font-family-body;
      margin-bottom: 0.813rem;

    }
    .field {
      input {
        border: $input-border;
        width: 100%;
      }
    }
    .footer {
      .btn {
        background-color: black;
        color: white;
        line-height: 1.2rem;
        width: 100%;
      }
    }
    .success {
      font-style: normal !important;
      color: black !important;
    }
    .cancel-btn {
      display: block;
      color: #4B4B4B !important;
      font-family: $font-family-body;
      text-decoration: underline;
      font-weight: 400;
      margin: auto;
      width: 100%;
      text-align: center;
    }

    .check-image {
      @media only screen and (max-width: 992px) {
        padding-left: 0;
        padding-right: 0;
        width: 50px;
      }
    }

    .success-text {
      @media only screen and (max-width: 992px) {
        padding-left: 0;
      }
    }
  }
}
