// === Brands page
.brands {
  .list {
    padding-bottom: 1rem;
  }

  .card {
    .card-body {
      height: 16rem;
      overflow: hidden;

      margin-bottom: 40px;

      .description {
        font-size: $font-size-small;
        line-height: 1.25rem;
        height: 10rem;
        overflow: hidden;
      }

      .brandLink {
        position: absolute;
        bottom: var(--bs-card-spacer-y);
      }
    }
  }
}


