// ==== Breadcrumbs
//----------------
//

// === Breadcrumbs
#breadcrumbs {
  font-size: 0.8125rem;
  font-family: $font-family-body;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 40px;

  .title {
    font-weight: bold;
  }

  a {
    color: black;
    text-decoration: none;
    font-weight: 400;
  }
}

#main_content.home {
  #breadcrumbs {
    @media screen and (max-width: 992px) {
      padding-left: 0.875rem !important;
    }
  }
}
