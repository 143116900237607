.navbar>.container {
  @media screen and (min-width: 1224px) {
    padding: 0 !important;
  }
}


// === Cookie Notification
.modal-notice {
  --bs-modal-width: 362px !important;

  @media only screen and (min-width: 992px) {
    --bs-modal-width: 661px !important;
  }

  .modal-dialog {
    z-index: 2000;
    position: relative;
  }

  .modal-content {
    width: 661px !important;

    @media only screen and (max-width: 992px) {
      width: 362px !important;
      margin: auto;
    }

    .modal-header {
      padding: 1.75rem 1.875rem 1rem 1.875rem;

      @media only screen and (max-width: 992px) {
        padding: 1.5rem 1.25rem 0.875rem 1.25rem;
      }

      border-bottom: none;

      .modal-title {
        font-family: $font-family-header;
        font-size: 1.75rem;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0;
        margin: 0;

        @media only screen and (max-width: 992px) {
          font-size: 1.375rem;
          font-weight: 600;
        }
      }
    }

    .modal-body {
      font-family: $font-family-body;
      font-size: $font-size-base;
      padding: 0 1.875rem 1.875rem 1.875rem;

      @media only screen and (max-width: 992px) {
        padding: 0 1.25rem 1.688rem 1.25rem;
      }

      p {
        padding: 0;
        margin: 0 0 1.25rem;
      }

      button {
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        padding-left: 2rem;
        padding-right: 2rem;

        @media only screen and (max-width: 992px) {
          width: 100%;
        }
      }

      .btn-deny-cookie {
        border: 0.063rem solid $color-pitch-black !important;
        padding-left: 2.188rem;
        padding-right: 2.188rem;

        @media only screen and (min-width: 992px) {
          margin-left: 0.625rem;
        }
      }

      .btn-accept-cookie {
        @media only screen and (max-width: 992px) {
          margin-bottom: 9px;
        }
      }
    }
  }
}


//Navbar mobiel
.mobile-header{
  #sticky-header {
    position: relative;
    height: 58px;
    width: 100vw;
    z-index: 100;
    transition: all .5s ease;
  }
  #sticky-header.scrolled{
    position: fixed;
    top: 0;
  }
  #sticky-header.hide {
    top: -58px;
  }
  .navbar-mobile {
    align-items: center;
    width: 100%;
    background-color: $color-cotton-candy;
    height: 58px;
    --bs-navbar-padding-y: none;

    .container-fluid {

      .navbar-left {
        align-self: center;
        justify-self: start;
        text-align: left;
        display: flex;
        align-items: center;

        .menu-icon{
          width: 30px;
          height: 20px;
          -webkit-appearance: none;
          -moz-appearance: none;

          img{
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        button {
          &.btn {
            &.search {
              width: 30px;
              height: 30px;
              padding-left: 0;

              img{
                transform: rotate(-15deg);
              }
            }
          }
        }
      }

      .navbar-middle {
        display: grid;
        position: absolute;
        left: 50%;
        right: 50%;
        top: 14px;

        .navbar-brand {
          align-self: center;
          justify-self: center;
          text-align: center;
          color: black;
          padding-right: 0;
          margin-right: 0;
          font-size: 1.125rem;
          font-family: 'Bely Display W00 Regular', serif;
          line-height: 0;
          .tv-logo {
            width: 161px;
            height: 21px;
          }
        }
      }

      .offcanvas-navbar, #offcanvas_languages {
        background-color: $color-cotton-candy;
        --bs-offcanvas-width: 90% !important;

        .offcanvas-header {
          padding: 0.8rem 1.25rem 0.25rem 1.063rem;

          .btn-close {
            background-image: url("../images/icons/MobileMenu_cross.svg");
            font-size: 21px;
            color: $color-pitch-black;
            opacity: 1;
            padding-right: 0;
            z-index: 999;
            border-radius: 0;
            border: none;
          }
        }

        .offcanvas-mobile-account {
          font-size: 1rem;
          text-transform: uppercase;

        a {
          text-decoration: none;
          color: black;

          span {
            font-family: $font-family-header;
            font-weight: 400;
            font-size: 0.813rem;
            text-transform: uppercase;
            margin-left: 0.25rem;
            top: 2px;
            position: relative;
          }
        }
      }

        .list {
          &.languages-currency {
            background-color: #FDF7F5;

            .nav-item:not(:last-child) {
              border-bottom: 0.063rem solid #0000004D !important;
            }

            &.menu-close {
              transform-origin: 0% 0%;
            }

            .submenu {
              list-style: none;
              background-color: $color-cotton-candy;
              padding-left: 0;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 21;
              transform-origin: 0 0;
              transform: translate(-100%, 0);
              transition: left 0.4s ease-in-out;

              .back-button {
                padding-bottom: 0.5rem;
                padding-top: 1rem;
                margin-left: -4px;

                .back {
                  cursor: pointer;
                  font-size: 1rem;
                  font-family: "Josefin Sans", sans-serif;
                  font-weight: 400;

                  picture {
                    text-align: left;

                    img {
                      width: 20px;
                      height: 20px;
                      text-align: left;
                      color: $color-pitch-black;
                      opacity: 1;
                    }
                  }
                }
              }

              li {
                border-bottom: 0.063rem solid #0000004D;

                a {
                  color: $color-pitch-black;
                  text-decoration: none;
                  font-size: 1rem;
                  font-family: "Josefin Sans", sans-serif;
                  font-weight: 400;
                  text-transform: uppercase;
                  padding: 0.875rem 0.5rem 0.75rem 0;
                  display: block;
                  height: 100%;
                  width: 100%;
                  line-height: 1.563rem;
                }

                &:last-child {
                  border-bottom: none;
                }

                &:first-child {
                  border-bottom: none;
                }
              }
            }
          }
        }


        .list-menu:not(:last-child) {
          border-bottom: 0.063rem solid #0000004D !important;
        }

        .nav-item {

          .language-selection, .currencies-selection {
            font-family: $font-family-header;
            font-size: 0.813rem;
            text-transform: uppercase;
            font-weight: 400;
            padding-top: 1rem;
            padding-bottom: 0.65rem;
            display: block;
            width: 100%;
            height: 100%;
            line-height: 1.563rem;
          }
        }

        .list-item {
          text-transform: uppercase;
          text-decoration: none;
          color: black;
          font-size: 1rem;
          font-family: $font-family-header;
          font-weight: 400;
          display: block;
          height: 100%;
          width: 100%;
          padding-top: 1rem;
          padding-bottom: 0.65rem;
          line-height: 1.563rem;

          &.sale-button {
            color: #D70926 !important;
          }

          &.customer-service-mobile {
            font-family: $font-family-header;
            font-size: 0.813rem;
            text-transform: uppercase;
            font-weight: 400;
          }
        }
      }

    .offcanvas-searchbar {
      background-color: $color-cotton-candy;
      --bs-offcanvas-width: 100% !important;
      transition: none;

      form {
        border-bottom: 1px solid black;
        padding-bottom: 5px;
      }

      button {
        &.text-reset {
          background-color: transparent;
          border: none;
          width: 33px;
          height: 33px;
          margin-top: 8px;
          padding-left: 0;

          img {
            position: relative;
            left: -7px;
          }
        }
      }

      .search-icon-in-input {
        img {
          transform: rotate(-15deg);
          margin-right: -5px;
          margin-top: 2px;
        }
      }

      input.autocomplete, .search-icon-in-input {
        background: none;
        border: none;
        padding-left: 0;

        &:focus {
          border: none !important;
        }
      }
    }

      .navbar-right {
        align-self: center;
        justify-self: end;
        text-align: right;
        display: flex;

        a {
          text-transform: uppercase;
          position: relative;
          margin: 0;
          font-size: 0.813rem;
          //line-height: 38px;
          font-family: $font-family-header;
          font-weight: 400;
          width: 31px;
          height: 34px;

          &.customer-service {
            padding-top: 5px !important;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .subtitle-homepage {
    background-color: #F9EEF1;
    position: relative;
    width: 100%;

    h2 {
      text-align: center;
      font-size: 1rem;
      color: #CA9E67;
      margin-bottom: 0;
      font-style: italic;
      font-family: "Satisfy Regular", serif;
    }
  }
}

.autocomplete-suggestions {
  background: white;
  border: 0.063rem solid #B1B1B1;
  border-top: none;
  max-height: none !important;

  @media only screen and (max-width: 992px) {
    background: $color-cotton-candy;
    border: none;
    width: 100% !important;
    top: calc(74px + 0.5rem) !important;
    left: 0 !important;
    padding: 0 1rem !important;
    z-index: 9998 !important;
    height: calc(100vh - 74px - 0.5rem);
    overflow-y: scroll;
    margin-top: 14px;

    &.show {
      position: fixed !important;
      display: block !important;
    }
  }

  .autocomplete-suggestion, .autocomplete-group {
    border-top: none;
    border-bottom: none;
    color: black;
    padding: 0.75rem 0.5rem;
    font-family: $font-family-body;
    font-size: 1rem;
    display: block !important;

    @media only screen and (max-width: 992px) {
      background: transparent;
      color: black;
      border: none;
      line-height: 1rem;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .autocomplete-suggestion {
    cursor: pointer;

    &:first-of-type {
      padding-top: 1.125rem;
    }

    &.autocomplete-selected {
      background: #EFEFF0;
    }

    strong {
      font-weight: 600;
    }

    @media only screen and (max-width: 992px) {
      padding: 13px 0;

      &.last-group-suggestion {
        padding-bottom: 26px;
      }

      &.autocomplete-selected {
        background: none;
      }
    }
  }

  .autocomplete-group {
    &:empty {
      display: none !important;
    }

    font-weight: 700;
    padding: 10px 0.5rem;
    line-height: initial;
    background-color: #FBF8F4;
    font-size: 13px;

    @media only screen and (max-width: 992px) {
      font-weight: unset;
      background: none;
      font-size: 18px;
      padding: 23px 0 13px 0;
      border-top: 1px solid #B9B9B9;

      &:first-of-type {
        border: none;
        padding: 13px 0 13px 0;
      }
    }
  }
}

//Navbar lg and up
.navbar-desktop {
  align-items: center;
  width: 100%;
  background-color: $color-cotton-candy;
  height: 58px;
  padding-top: 11px;
  padding-bottom: 11px;

  .navbar-left {
    width: 300px;
    align-items: center;
    position: relative;

    .collapsable-search {
      background-color: transparent;
      z-index: 1001;

      .search-holder {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        //width: 0;
        //overflow: hidden;
        //transition: width 500ms ease-in-out;
        //background-color: transparent;

        .form-control {

          &:focus {
            outline: none;
          }
        }

        .search-icon-in-input {
          position: absolute;
          right: 0;
          cursor: pointer;
          padding-left: 0;
          height: 41px;
          width: 35px;
          top: 5px;
          transition: 300ms right ease-in-out;

          img{
            transform: rotate(-15deg);
            padding-top: 6px;
            padding-right: 3px;
            margin-top: -1px;
          }
        }

        input {
          width: 2rem;
          background: none;
          border-radius: 0;
          font-family: $font-family-body;
          padding: 8px 10px;
          border: 1px solid transparent;

          &::placeholder {
            opacity: 0;
            transition: 300ms opacity ease-in-out;
          }
          transition:
                  300ms width ease-in-out,
                  300ms padding-left ease-in-out,
                  300ms background ease-in-out,
                  300ms border ease-in-out;
        }
      }

      button {
        &.search {
          padding: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: none;

          img{
            transform: rotate(-15deg);
            margin-top: 6px;
          }

          &:focus, &:active {
            outline: none !important;
            box-shadow: none !important;
          }
        }
      }

      &.show-searchbar {
        background-color: white;

        .search-holder {
          input {
            width: 300px;
            background: white;
            padding-right: 31px;
            border: 1px solid #B1B1B1;

            &::placeholder {
              opacity: 1;
            }
          }

          .search-icon-in-input {
            //padding-left: 41px;
            right: 7px;
          }
        }
      }
    }

    .lang-select, .currency-select {
      border: 0.063rem solid black;
      border-radius: 2rem;
      line-height: 1.7;
      text-align: center;
      width: 2.5rem;
      height: 1.5rem;
      padding: 0.125rem;

      cursor: pointer;

      span {
        display: block;
        height: 100%;
        width: 100%;
        text-transform: uppercase;

        font-family: "Josefin Sans", sans-serif;
        font-size: 0.813rem !important;

        &:focus, &:active, &:hover, &:focus-visible {
          outline: none !important;
          box-shadow: none !important;
          padding: 0;
        }
      }
    }

    .lang-select {
      margin-right: 0.3rem;

      .ico {
        display: inline-block;
        position: relative;
        text-indent: 0;
        vertical-align: middle;
      }

      .dropdown-menu {
        --bs-dropdown-min-width: 4.688rem;
      }

      .dropdown-item {
        padding-left: 0.5rem;
        font-size: 0.813rem;
        font-family: "Josefin Sans", sans-serif;
        text-transform: uppercase;
      }
    }

    .language-currency-buttons {
      position: absolute;
      left: 37px;
    }

    .currency-select {

      .dropdown-menu {
        --bs-dropdown-min-width: 4.688rem;
      }

      .dropdown-item {
        padding-left: 0.5rem;
        font-size: 0.813rem;
        font-family: "Josefin Sans", sans-serif;
      }
    }
  }

  .navbar-middle {
    .navbar-brand {
      align-self: center;
      justify-self: center;
      text-align: center;
      padding-right: 0;
    }
  }

  .navbar-right {
    width: 300px;

    a {
      position: relative;
      margin: 0;
      //height: 38px;

      &.customer-service {
        font-weight: 400;
        font-family: 'Josefin Sans', sans-serif;
        //line-height: 38px;
        font-size: 0.813rem;
        text-transform: uppercase;
      }
    }
  }
}

//Second navbar with megamenu on screens lg and up

.navbar-big-screens {
  background-color: $color-cotton-candy;

  .has-megamenu {
    position: static !important;

    .nav-link:lang(fr){
      padding-right: 0.25rem !important;
      span{
        font-size: 0.9375rem;
      }
    }

  }

  .dropdown-hover:hover > .dropdown-menu {
    display: inline-block;
  }

  .dropdown-hover > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
  }

  .category-title {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1rem;
    //Voor wanneer er meer dan 10 megamenu items zijn
    &.category-title-small{
      font-size: 0.813rem;
    }
  }

   .navbar-nav {
      .nav-item {
        //font-family: 'Josefin Sans', serif !important;
        a > span {
          color: black;
          font-weight: 400;
          text-transform: uppercase;
        }
        .sale-button > span{
          color: #D2070D !important;
        }
      }

     li:first-child {
       a.nav-link {
         padding-left: 0 !important;
       }
     }
     li:last-child {
       a.nav-link {
         padding-right: 0 !important;
       }
     }

     a.active {
       font-weight: 900;
       color: black !important;
     }
    }

    .megamenu {
      background-color: $color-cotton-candy;
      left: 0;
      right: 0;
      width: 100%;
      margin-top: 0;
      border: none;
      border-radius: 0;

      @media all and (min-width: 992px) {
        .nav-item:hover .list-unstyled {
          display: block;
        }
        .nav-item .list-unstyled {
          margin-top: 0;
        }
      }

      .megamenu-container {
        @media only screen and (min-width: 1224px) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .megamenu-row {
        display: flex;
      }

      .title {
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: 600;
        font-family: 'Josefin Sans', sans-serif;
        text-transform: uppercase;
      }

      ul {
        list-style: none;
        padding-left: 0 !important;
        margin-left: 0 !important;
        margin-top: 1rem !important;

        li {
          padding: 0;
          margin: 0;

          &.view_more {
            font-weight: 600;
            a{
              font-weight: 600;
              color: #D59F61;
              line-height: 0;
              font-family: $font-family-body;
            }
          }

          a {
            color: black;
            font-family: $font-family-body;
            font-weight: 400;
            text-decoration: none;
            line-height: 1.875rem;
            font-size: $font-size-base;
          }
        }
      }
    }

    .megamenu-column {
      padding-right: 1.5rem;

      &:last-of-type {
        padding-right: 0;
      }

      &.image {
        flex: 0 0 223px;
        object-fit: cover;
        position: relative;

        a {
          display: inline-block;
          aspect-ratio: 223/293;
          position: relative;

          picture{
            width: 223px;

            img{
              object-fit: cover;
            }
          }
        }

        .btn {
          position: absolute;
          width: auto;
          max-width: 90%;
          bottom: 10%;
          transform: translateX(-50%);
          margin: 0 50%;
          z-index: 5;
          background-color: white;
          color: black;
          white-space: nowrap;

          &.btn-minwidth {
            min-width: 124px;
          }

          &.btn-ellipsis {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
//Sectie titel homepage mobile

.notificationbar {
  background-color: $color-amethyst-mist;
  height: auto;
  color: white;
  align-items: center;
  overflow: hidden;

  .notificationbar-content{
    margin-left: 5px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-size: 0.813rem;
    height: 100%;
    line-height: 1rem;
    padding: 6px 0;
    font-weight: 700;
    color: white;
    font-family: 'Quicksand', sans-serif;


    .countdown-pair{
      margin-left: 1px;

      .countdown{
        font-variant-numeric: tabular-nums; /* Use tabular numbers if available */
        display: inline-block;
        width: 12px;//1.5ch; /* Fixed width for alignment */
        text-align: center;
      }

      .threeNumbers{
        width: 17px !important;
      }
    }
  }

  &.scrolled{
    margin-top: 58px !important;
  }

  span {
    display: inline-block;
  }

  a {

    &:hover{
      color: white;
    }
  }

  .close {
    margin-left: 5px;
    cursor: pointer;
  }

  // Style options in Pulse
  &.hotpink {
    a{
      color: white;
    }
    color: white;
    background: $color-pink-dark;
  }

  &.goldenbrown {
    a {
        color: white;
    }
    color: white;
    background-color: $color-golden-brown;
  }

  &.gingergold {
    a {
      color: white;
    }
    color: white;
    background-color:$color-ginger-gold;
  }

  &.cocoacream {
    a {
      color: black;

    }
    color: black;
    background-color: $color-cocoa-cream;
  }

  &.cottoncandy {
    a {
      color: black;
    }
    color: black;
    background-color: $color-cotton-candy;
  }

  &.tassleflower {
    a {
      color: black;
    }
    color: black;
    background-color: $color-tassle-flower;
  }

  &.macaroonrose {
    a {
      color: white;
    }
    color: white;
    background-color: $color-macaroon-rose;
  }

  &.superrose {
    a {
      color: black;
    }
    color: black;
    background-color: $color-super-rose;
  }

  &.pitchblack {
    a {
      color: white;
    }
    color: white;
    background-color: #000000;
  }

  &.gold {
    a {
      color: black;
    }

    color: black;
    background: rgb(154, 129, 63);
    background: linear-gradient(90deg, rgba(154, 129, 63, 1) 0%, rgba(195, 162, 80, 1) 50%, rgba(154, 129, 63, 1) 100%);
    font-weight: bold;
  }
}
.title-homepage.scrolled{
  margin-bottom: 58px;
}

.subtitle-homepage {
  background-color: #F9EEF1;

  h2 {
    text-align: center;
    font-size: 1rem;
    color: #CA9E67;
    margin-bottom: 0;
    font-style: italic;
    font-family: "Satisfy Regular", serif;
  }
}

.mobile-header-test{
  .notification-bar{
    position: relative;
    display: block;
    background-color: yellow;
  }
  .nav-bar{
    height: 58px;
    position: sticky;
    top: 0;
    background-color: red;
  }
  .subtitle-homepage{
    display: block;
    background-color: #0dcaf0;
  }
}

.header-icon {
  position: relative;

  .count, .logged-in {
    display: block;
    position: absolute;
    top: -0.25rem;
    right: 0;
    text-align: center;
    width: 21px;
    height: 21px;

    background-color: $color-white;
    border-radius: 50%;
    color: $color-super-rose;
    font-family: $font-family-body;
    font-size: 0.813rem;
    line-height: 1.313rem;
    font-weight: 700;
  }

  .logged-in {
    width: 18px;
    height: 18px;
    line-height: 1.375rem;
    background-repeat: no-repeat;

    &::after {
      display: inline-block;
      position: relative;
      content: url("../images/icons/check-logged-in.svg");
      width: 12px;
      top: -0.063rem;
    }

    &.mobile-logged-in {
      top: -0.25rem;
      right: -0.25rem;

      &::after {
        width: 12px;
        top: -0.063rem;
      }
    }
  }

  .count:empty {
    display: none;
  }
}
