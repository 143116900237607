.pagination {
  .page-item {
    text-transform: capitalize;
    font-weight: 600;

    @media screen and (max-width: 992px) {
      font-family: $font-family-body;
      font-size: 1rem;
      font-weight: 700;
    }
    a{
      padding: 0.156rem !important;

      &:hover, &:focus{
        background-color: transparent;
      }
    }

    .page-link {
      font-family: $font-family-body;
      font-size: 1rem;
      padding: 0.5rem;
      text-decoration: none;
      color: black;
      min-width: 32px;
      text-align: center;
      border: none;
      margin: 0 !important;

      &.active {
        color: black;
        border: 0.063rem solid black;
        border-radius: 6.25rem !important;
        background-color: transparent;
      }
      &.next, &.previous{
        color: $color-ginger-gold;
        font-family: $font-family-body;
        font-weight: 700;
      }
    }
  }
}
