//Voor en door vrouwen
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

  #video-container{
    position: relative;
    #video-player{
      width: 100%;
      position: relative;
      object-fit: inherit;
    }
  }

.storeWidget {

  @media( max-width: 992px ) {
    margin: 0;
  }

  .swiper-slide {
    display: flex;
    width: 100%;
    height: 510px;
    font-size: 1rem;

    @media(max-width: 992px) {
      height: unset;
      display: block;
    }

    .slider-image {
      vertical-align: top;
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;

      picture,
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .slide-image-block {
        position: absolute;
        padding: 8px 20px;
        background-color: rgba(253,242,241,0.8);
        bottom: 35px;
        font-size: 18px;
        min-width: 248px;
        line-height: 1.2em;
        z-index: 3;

        &.top {
          bottom: unset;
          top: 35px;
        }
      }

      .slide-image-subtitle {
        font-weight: bold;
      }
    }

    .slider-text {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      vertical-align: top;
      width: 100%;
      padding: 30px;
      color: black;
      background-color: $color-cotton-candy;

      @media(max-width: 992px) {
        padding: 20px;
        display: block;
      }
    }
  }
  &.single-slide .swiper-button-prev, &.single-slide .swiper-button-next {
    display: none;
  }
}

#blog-container{
  width: 100%;
  line-height: 1.5;
  font-size: 15px;
  .more-posts{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .post-container {
      background-color: $color-cotton-candy;
      display: inline-block;
      position: relative;
      vertical-align: top;
      text-align: center;
      flex-basis: 32%;
      margin: 0 1%;

      &:first-child,
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      &:last-child,
      &:nth-child(3n) {
        margin-right: 0;
      }

      @media( max-width: 992px ) {
        flex: 100%;
        margin: 10px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .post-image {
        overflow: hidden;
      }

      .post-image img {
        position: relative;
        width: 100%;
        height: auto;
        // to center the image
        top: 50%;
        bottom: 50%;
        transform: none;
      }

      .post-underimage {
        padding: 10px;
        min-height: 106px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a:hover {
        text-decoration: none;

        .post-read-more {
          color: $color-ginger-gold;
          text-decoration: underline;
        }
      }

      .post-title {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 10px;
        text-align: center;
        color: black;
      }
    }
  }
}
