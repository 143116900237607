// === Colors TopVintage
// ---------------------
//

// === Brand colors TopVintage
$color-pink-light:        #f9e2e2;
$color-pink-medium:       #f8ced2;
$color-pink-dark:         #f6617b;
$color-purple-dark:       #504e71;
$color-green-dark:        #3f9e84;
$color-gray-dark:         #898885;
$color-text:              #4f4e70;

$topvintage-pink:         #f9e2e2;
$topvintage-hot-pink:     #d16174;
$topvintage-purple:       #504e71;

$topvintage-green-darker: #1A3F39;
$topvintage-grey:         #CCCCCC;
$topvintage-gold:         #f0cf72;
$topvintage-gold-darker:  #C8AE7C;
$topvintage-brown:        #a87920;
$color-golden-brown:      #B98004;
$topvintage-success-green:#809848;

$color-background:        $color-pink-light;
$color-white:             #FFFFFF;
$color-background-blog:   #FDF2F1;
$text-color:              $color-gray-dark !default;

$color-ginger-gold:       #D89A3F;
$color-cocoa-cream:       #E8D1C1;
$color-cotton-candy:      #F6E3E7;
$color-tassle-flower:     #f8C6D2;
$color-macaroon-rose:     #EC657E;
$color-super-rose:        #D70926;
$color-amethyst-mist:     #A795C8;
$color-pitch-black:       #000000;

$input-border:            0.063rem solid #D1D1D1;

