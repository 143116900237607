.newsletter-subscribe-widget {
  .input-div-newsletter {
    .spinner-border {
      height: 26px;
    }
  }

  .success-message {
    display: inline-flex;
    justify-content: left;
    text-align: left;
    width: 95%;

    picture{
      width: auto !important;
    }

    img {
      width: 43px;
      height: 43px;
      display: inline-flex;
    }

    span {
      font-family: $font-family-body;
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
      display: inline-flex;
      margin-left: 0.5rem;
      flex: 1;
    }
  }

  form {
    input {
      width: 342px;
      margin: auto;
      display: inline-flex;
      height: 50px;
      border-radius: 0;
      border: $input-border;
      font-family: $font-family-body;
      font-size: $font-size-base;
      transition: border 200ms;
      text-align: left;
      background-color: white;

      @media only screen and (max-width: 991px) {
        width: calc(100% - 47px);
      }

      &::placeholder{
        color: black;
      }

      &:focus{
        border: $input-border;
      }
    }
  }

  .btn {
    background-color: #000000;
    color: white;
    width: 46px;
    height: 50px;
    border-radius: 0 !important;
    font-weight: 600;
    display: inline-flex;
    margin-bottom: 0.25rem;
    margin-left: -0.313rem;

    .subscribe-text {
      img {
        margin-left: 1px;
      }
    }

    p {
      margin-bottom: 0;
    }

    &:focus, &:active, &:focus-visible, &:hover {
      background-color: #000000 !important;
      color:white !important;
    }
  }
}

