@import "mixins";

// Setting some variables
$sticky-buttons-height: 24px;
$sticky-width: 50px;
$sticky-padding: 0.625rem;

* {
  box-sizing: border-box;
}

*:focus, *:active {
  outline: none !important;
  box-shadow: none !important;
}

// Some main elements
html, body {
  height: 100%;
  margin: 0;
}

body {
  overflow-x: hidden;
  font-family: $font-family-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-header;
}

.modal-content{
  border-radius: unset !important;
  border: unset !important;
}

#main_content {
  overflow-x: hidden;

  // DISABLE CONTENT ON AJAX CALL
  &.disabled,
  .disabled:not(.switcher) {
    opacity: 0.5;
    pointer-events: none;
  }

  &.scrolled{
    margin-top: 58px !important;
  }

  > .container {
    @media screen and (max-width: 1224px) {
      padding: 0 14px !important;
    }

    @media screen and (min-width: 1224px) {
      padding: 0 !important;
    }
  }
}



#main_content.home {
  .container {
    padding: 0 !important;
  }
}

p {
  font-family: $font-family-body;
}

.section-tp, .title-tp {
  padding-top: 40px;
  @media screen and (max-width: 992px) {
    padding-top: 37px;
  }
}

.section-p {
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (max-width: 992px) {
    padding-top: 37px;
    padding-bottom: 37px;
  }
}

.section-m {
  margin-top: 40px;
  margin-bottom: 40px;
  @media screen and (max-width: 992px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

section {
  position: relative;
  max-width: 100vw;
}

.hidden {
  display: none !important;
  visibility: hidden;
  opacity: 0;
}


h1.page-title {
  font-family: $font-family-header;
  font-weight: 600;
  font-size: 2.125rem;
  padding-top: 2.5rem;
}

// End main elements

.sidebar-padding-mobile {
  @media only screen and (max-width: 992px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

// Picture/image/video incl lazy-loading styling
picture, .ns-picture,
video, .ns-video {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  transition: opacity ease-in-out 200ms;
  z-index: 2;
  overflow: hidden;
  text-align: center;
  margin: 0;

  img {
    width: 100%;
    min-height: 100%;
    vertical-align: middle;
    object-fit: contain;
    object-position: top;
    pointer-events: none;

    &.blur-up {
      -webkit-filter: blur(0.313rem);
      filter: blur(0.313rem);
      transition: filter 100ms, -webkit-filter 100ms;
    }

    &.blur-up.lazyloaded {
      -webkit-filter: blur(0);
      filter: blur(0);
    }
  }
}

// End picture/image/video


// Custom styling classes

//button background colors
.btn-bg-black {
  background-color: #000000 !important;
  color: #FFFFFF !important;
}

.btn-bg-white {
  background-color: #FFFFFF !important;
  color: #000000 !important;
}

.btn-bg-pink {
  background-color: $color-cotton-candy !important;
  color: #000000 !important;
}
// End Custom styling classes

// Webkit Appearance on all buttons (Fix for Safari)
button,
[type="button"] {
  -webkit-appearance: none !important;
  -moz-appearance: none;
  appearance: none;
}

//Default button that gets used throughout the whole website
.btn {
  min-height: 50px;
  padding: 0.625rem;
  font-size: 1rem !important;
  font-family: $font-family-button !important;
  font-weight: 600 !important;
  display: inline-flex;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  border: none !important;
  border-radius: 10px !important;
  transition: opacity 0.2s;

  &:focus, &:active, &:focus-visible, &:hover {
    border: none !important;
  }

  &:hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}
// End default button

// Warnings
.alert {
  display: block;
  color: $color-pitch-black;
  font-size: $font-size-small;
  padding: 0;

  &.general {
    font-size: $font-size-base;
  }

  &.success {
    color: $topvintage-success-green;
  }

  &.warn, &.error {
    color: $color-super-rose;
  }
}

.warn-text {
  color: $color-super-rose;
}

.warning-general {
  .warn-text {
    color: $color-pitch-black;
  }
}

input, select, textarea {
  &.warn {
    border-color: $color-super-rose !important;
  }
}

.alert-box {
  display: inline-block;
  padding: 0 10px;
  background-color: #f2f2f2;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;

  &.show {
    padding: 10px 10px;
    max-height: 500px;
    display: inline-block;
  }
}
// End warning


// Accordion header
.accordion-header { // Is the general?
  .accordion-button {
    --bs-accordion-btn-icon-width: 1.65rem;

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
  }
}
// End Accordion header


// Flush cache
.flushcontainer {
  display: block;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1200;

  button {
    height: 30px !important;
    appearance: auto !important;
    font-weight: normal !important;
    font-size: 0.875rem !important;
    font-family: $font-family-button !important;
    margin: 0 0 0.313rem !important;

    background: #808080 !important;
    border: 0.063rem solid #808080 !important;
    padding: 0.125rem 0.625rem;
    color: white !important;
  }
}
// End Flush cache


// Wishlist heart and counter
.wishlist {
  display: inline-block;
  color: black;
  font-size: 0.8rem;

  span.wishlist-icon {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 35px;
    height: 31px;
    object-fit: cover;
    background-size: cover;

    &.wishlist-heart {
      background-image: url("../images/icons/heart-logo.svg");
    }

    &.in-wishlist {
      background-image: url("../images/icons/heart-logo-active.svg");
    }

    &.wishlist-trashcan {
      background-image: url("../images/icons/trashcan.svg");
      width: 19px;
      height: 19px;
      @media screen and (max-width: 992px) {
        background-image: url("../images/icons/trashcan-active.svg");
      }
    }
  }

  .itemsWishlist {
    display: block;
    color: black;
    font-size: 1rem;
    text-align: center;
    font-family: $font-family-body;
    font-weight: 400;

    @media only screen and (max-width: 992px) {
      font-size: 0.813rem;
    }
  }
}
// End Wishlist

// Version-container
.version-container {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;

  &.vc-mobile {
    top: auto;
    right: auto;
    bottom: 5px;
    left: 10px;
  }
}
// End version-container


.flags-container {
  display: inline-block;
  position: absolute;
  bottom: 8%;
  left: 0;
  //background-color: rgba(200,200,200,.5);

  .flag {
    //display: inline-block;
    position: relative;

    float: left;
    clear: left;

    height: 26px;
    //width: auto;
    z-index: 2;

    margin-top: 0.5rem;
    padding: 0.25rem 0.5rem 0.25rem 0;
    background-color: #d2070d;
    color: #fff;

    font-family: "Josefin Sans", sans-serif;
    font-size: 0.813rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-transform: uppercase;

    i.icon {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      background-position: center;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      height: 18px;
      width: 0;
      margin: 0 0.25rem 0.25rem;
    }

    &.sale,
    &.deal,
    &.live,
    &.red {
      background-color: #d2070d;
    }

    &.hot,
    &.black {
      background-color: black;
    }

    &.hot {
      i.icon {
        width: 20px;
        background-image: url("../images/icons/hot.png");
      }
    }

    &.new {
      background-color: black;
    }

    &.eco,
    &.green {
      background-color: $color-green-dark;
    }

    &.blackfriday,
    &.gold {
      background-color: $color-ginger-gold;
      color: black;
    }

    &.birthday,
    &.roze {
      background-color: $color-macaroon-rose
    }

    &.party,
    &.purple {
      background-color: $color-amethyst-mist;
      color: black;
    }

    &.party {
      i.icon {
        width: 20px;
        background-image: url("../images/icons/party.png");
      }
    }

    &.soon,
    &.biege {
      background-color: $color-cocoa-cream;
      color: black;
    }
  }
}

.no-pointers {
  pointer-events: none;
}

// no selecting and copying of text in a button or link
a, .btn {
  @include user-select(none);

  &:hover {
    cursor: pointer;
  }
}

.filters, .offcanvas-mobile-filters {
  .filter {
    @include user-select(none);
    cursor: pointer;

    &:hover {
      cursor: pointer !important;
    }
  }
}

/* Overwrite border for default Bootstrap offcanvas */
.offcanvas {
  border: 0 !important;
}

.topvintage-checkbox, .topvintage-radiobutton {
  background-color: $color-white;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 8px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  @include appearance(none);

  &:after {
    left: 8px;
    top: 2px;
    width: 7px;
    height: 14px;
    content: "";
    position: relative;
    display: none;
    border: solid $color-white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:checked {
    background-color: black;

    &:after {
      display: block;
    }
  }
}

.topvintage-radiobutton {
  border-radius: 50%;

  &:after {
    width: 19px;
    height: 19px;
    background: black;
    border: none;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transform: none;
  }

  &:checked {
    background-color: black;

    &:after {
      display: block;
    }
  }
}
