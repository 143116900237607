.widget.email-preferences {

  @include user-select(none);

  h3 {
    font-weight: 700;
    font-family: "Quicksand",sans-serif;
    font-size: 20px;
  }

  form {
    display: flex;
    flex-wrap: wrap;
  }

  .email-options {
    margin: 25px 0;
  }

  label {
    display: flex;
    align-items: start;
    cursor: pointer;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .option-text {
      display: flex;
      flex-wrap: wrap;
      flex: 1;

      .option-text-title {
        font-weight: bold;
        width: 100%;
      }
    }
  }

  .submit-button {
    width: 100%;

    button {
      min-width: 160px;
    }
  }


  .unsubscribe-all {
    text-decoration: underline;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .alert-box.show {
    margin-bottom: 27px;
  }
}
