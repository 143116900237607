@media only screen and (max-width: 992px){
//This is an exception to hide the sidemenu on CS page for mobile
  [data-reference="service"] {
    .submenu:not(#main_content) {
      display: none;
    }
  }
}

.submenu {
  &.account {
    @media only screen and (max-width: 992px) {
      display: none;
    }

    @media only screen and (min-width: 992px) {
      padding-right: 25px;
    }

    .welcome-title {
      font-family: "Satisfy Regular", sans-serif;
      font-style: italic;
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin-bottom: 29px;

      @media only screen and (min-width: 992px) {
        font-size: 2.188rem;
        line-height: 2.188rem;
      }
    }

    p {
      font-family: $font-family-body;
      font-size: $font-size-base;

      @media only screen and (min-width: 992px) {
        margin-top: 0.5rem;
      }

      &.last-order-title {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }

    .widget-last-order {
      margin-top: 1rem;
    }

    .last-order-card {
      .order-number {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.438rem;
      }

      .order-date, .order-status, .order-price {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.438rem;
      }

      .order-details {
        font-size: 1rem;
        font-weight: 600;
        color: #D59F61;
        text-decoration: none;
      }

      .account-image-order {
        @media only screen and (max-width: 992px) {
          margin-top: 0.3rem;
        }
      }
      
      picture {
        width: 67px;
        height: 97px;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 2rem;
      margin-bottom: 0;

      @media only screen and (min-width: 992px) {
        margin-top: unset;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: start;
        padding-bottom: 1rem;

        @media only screen and (max-width: 992px) {
          padding-bottom: 0.75rem;
          padding-top: 0.75rem;
          margin-left: -14px;
          margin-right: -14px;
          padding-left: 14px;
        }

        @media only screen and (max-width: 992px) {
          &:nth-child(even) {
            background: #FDF7F5;
          }
        }

        a {
          color: black;
          text-decoration: none;
          display: inline-flex;
          justify-content: start;
          align-items: center;
          font-size: 1rem;
          font-weight: 700;
          font-family: $font-family-body;
          height: 30px;

          picture {
            margin-right: 0.75rem;
            width: 26px;
            height: 30px;
            overflow: hidden;

            img {
              height: 26px;
              width: 26px;
              object-fit: contain;
              object-position: center !important;
            }
          }
        }
      }
    }
  }

  &.overall {
    ul {
      list-style: none;
      padding-left: 0;
      background-color: #FDF7F5;

      li {
        display: flex;
        align-items: center;
        justify-content: start;
        padding-bottom: 0.625rem;

        &:last-child {
          padding-bottom: 0;
        }

        a {
          color: black;
          text-decoration: none;
          display: inline-flex;
          justify-content: start;
          align-items: center;
          font-size: 1rem;
          font-family: $font-family-body;
          font-weight: 400;

          &.menuTitle {
            font-weight: 600;
          }

          &.active {
            color: $color-ginger-gold;
            font-weight: 700;
          }
        }
      }
    }
  }
}

[data-reference="my-topvintage"] .submenu.account {
  display: block;
}
